import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logout } from "../pages/redux/slice/authSlice";

const withAuth = (Component) => {
  const Auth = (props) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const adminInfo = useSelector((state) => state.auth.adminInfo);
    const token = adminInfo?.token;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
      if (token && isAuthenticated) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          dispatch(logout());
          navigate("/login", { replace: true });
          toast.success("Session is expired. please login again");
        } else {
          if (location.pathname === "/login" && isAuthenticated) {
            navigate("/blogs", { replace: true });
          }
        }
      } else {
        if (location.pathname === "/login" && isAuthenticated) {
          navigate("/blogs");
        } else if (!isAuthenticated && location.pathname !== "/login") {
          navigate("/login", { replace: true });
        }
      }
    }, [isAuthenticated, location.pathname, navigate]);

    if (typeof window === "undefined") {
      return null;
    }

    return <Component {...props} />;
  };

  return Auth;
};

export default withAuth;
