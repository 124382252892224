import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registerAdmin } from "../../../api";
import LoadingButton from "../../../component/LoadingButton";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [registerForm, setRegisterForm] = useState({
    email: "",
    name: "",
    role: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onRegister = () => {
    setIsLoading(true);
    setErrorMessage("");
    let obj = {
      email: registerForm.email,
      username: registerForm.name,
      role: registerForm.role,
      password: registerForm.password,
    };
    registerAdmin(obj)
      .then((res) => {
        if (res?.data) {
          navigate("/login");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);

        setErrorMessage(e?.response?.data?.message);
      });
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Stack
        alignItems="center"
        spacing={4}
        justifyContent="center"
        height={"100%"}
      >
        <Typography variant="h4">Register</Typography>
        <TextField
          placeholder="Enter Name"
          variant="outlined"
          sx={{ width: "400px" }}
          onChange={(e) =>
            setRegisterForm({ ...registerForm, name: e.target.value })
          }
          value={registerForm.name}
        />
        <FormControl>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            value={registerForm.role}
            labelId="demo-simple-select-label"
            placeholder="Enter Role"
            sx={{ width: "400px" }}
            label="Role"
            onChange={(e) =>
              setRegisterForm({ ...registerForm, role: e.target.value })
            }
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Writer">Writer</MenuItem>
          </Select>
        </FormControl>
        <TextField
          placeholder="Enter Email"
          variant="outlined"
          sx={{ width: "400px" }}
          onChange={(e) =>
            setRegisterForm({ ...registerForm, email: e.target.value })
          }
          value={registerForm.email}
        />

        <TextField
          placeholder="Enter Password"
          variant="outlined"
          sx={{ width: "400px" }}
          onChange={(e) =>
            setRegisterForm({ ...registerForm, password: e.target.value })
          }
          type={showPassword ? "text" : "password"}
          value={registerForm?.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          variant="contained"
          sx={{ width: 400 }}
          onClick={onRegister}
          isLoading={isLoading}
        >
          <Typography>Register</Typography>
        </LoadingButton>

        {errorMessage && (
          <Typography color={"red"}>Email is Already exist</Typography>
        )}
      </Stack>
    </Box>
  );
};

export default Register;
