import React from "react";

const boldTextBeforeColon = (text) => {
  const parts = text.split(":");
  if (parts.length > 1) {
    return `<strong>${parts[0]}</strong>:${parts[1]}`;
  }
  return text;
};

const List = ({ content }) => {
  const { items, style } = content.data;
  const isUnordered = style === "unordered";

  const listStyle = {
    margin: "20px 50px",
  };

  return (
    <div>
      {isUnordered ? (
        <ul style={listStyle}>
          {items.map((item, index) => (
            <li
              style={{ marginBlock: "14px" }}
              key={index}
              dangerouslySetInnerHTML={{
                __html: boldTextBeforeColon(item),
              }}
            />
          ))}
        </ul>
      ) : (
        <ol style={listStyle}>
          {items.map((item, index) => (
            <li
              style={{ marginBlock: "14px" }}
              key={index}
              dangerouslySetInnerHTML={{
                __html: boldTextBeforeColon(item),
              }}
            />
          ))}
        </ol>
      )}
    </div>
  );
};

export default List;
