import React from "react";
import { Button, CircularProgress, Box } from "@mui/material";

const LoadingButton = ({ children, isLoading, disabled, ...props }) => {
  return (
    <Button {...props} p={2} disabled={isLoading || disabled}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={20} sx={{ mr: 1, color: "#ffff" }} />
        </Box>
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
