import axios from "axios";
import { store } from "../pages/redux/store";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

const getToken = () => {
  const state = store.getState();
  return state.auth?.adminInfo?.token;
};

//axios instance
axiosInstance.interceptors.request.use(
  function (config) {
    try {
      config.headers["Content-Type"] = "application/json";
      const token = getToken();
      if (token) {
        config.headers["authorization"] = `${token}`;
      }
      if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

//admin auth
export const adminLogin = (payload) => {
  let result;
  try {
    result = axiosInstance.post("/login", payload);
  } catch (error) {
    result = error;
  }
  return result;
};

//admin register
export const registerAdmin = (payload) => {
  let result;
  try {
    result = axiosInstance.post("/register", payload);
  } catch (error) {
    result = error;
  }
  return result;
};

//blog
export const createBlog = (payload) => {
  let result;
  try {
    result = axiosInstance.post("/create", payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const getAllBlogs = () => {
  let result;
  try {
    result = axiosInstance.get("/getAll");
  } catch (e) {
    result = e;
  }
  return result;
};

export const getSingleBlog = (id) => {
  let result;
  try {
    result = axiosInstance.get(`/get/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

export const updateBlog = (id, payload) => {
  let result;
  try {
    result = axiosInstance.put(`/update/${id}`, payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const deleteBlog = (id) => {
  let result;
  try {
    result = axiosInstance.delete(`/delete/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

//category
export const createCategory = (payload) => {
  let result;
  try {
    result = axiosInstance.post("/add-category", payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const getAllCategory = () => {
  let result;
  try {
    result = axiosInstance.get("/get-categories");
  } catch (e) {
    result = e;
  }
  return result;
};

export const getSingleCategory = (id) => {
  let result;
  try {
    result = axiosInstance.get(`/get-category/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

export const updateCategory = (id, payload) => {
  let result;
  try {
    result = axiosInstance.put(`/edit-category/${id}`, payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const deleteCategory = (id) => {
  let result;
  try {
    result = axiosInstance.delete(`/delete-category/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

//case study
export const createCaseStudy = (payload) => {
  let result;
  try {
    result = axiosInstance.post("/addCaseStudy", payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const getSingleCaseStudy = (id) => {
  let result;
  try {
    result = axiosInstance.get(`/getCaseStudy/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

export const getAllCaseStudy = () => {
  let result;
  try {
    result = axiosInstance.get("/getAllCaseStudies");
  } catch (e) {
    result = e;
  }
  return result;
};

export const updateCaseStudy = (id, payload) => {
  let result;
  try {
    result = axiosInstance.put(`/updateCaseStudy/${id}`, payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const deleteCaseStudy = (id) => {
  let result;
  try {
    result = axiosInstance.delete(`/deleteCaseStudy/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

//client review
export const addClientReview = (payload) => {
  let result;
  try {
    result = axiosInstance.post("/addReview", payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const getSingleClientReview = (id) => {
  let result;
  try {
    result = axiosInstance.get(`/getReview/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

export const getAllClientReview = () => {
  let result;
  try {
    result = axiosInstance.get("/getAllReviews");
  } catch (e) {
    result = e;
  }
  return result;
};

export const updateClientReview = (id, payload) => {
  let result;
  try {
    result = axiosInstance.put(`/editReview/${id}`, payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const deleteClientReview = (id) => {
  let result;
  try {
    result = axiosInstance.delete(`/deleteReview/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};
