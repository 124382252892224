import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { deleteCaseStudy, getAllCaseStudy } from "../../api";
import CaseStudyList from "./CaseStudyList";
import WarningModal from "../../component/WarningModal";
import withAuth from "../../hooks/withauth";

const CaseStudy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    fetchAllCaseStudies();
  }, [location]);

  const fetchAllCaseStudies = () => {
    setIsLoading(true);
    getAllCaseStudy()
      .then((res) => {
        if (res) {
          setIsLoading(false);
          setData(res?.data?.data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const onDelete = (id) => {
    setIsOpen(true);
    setDeleteId(id);
    setIsDeleteLoading(false);
  };

  const handleAddCaseStudy = (newCaseStudy) => {
    setData((prevData) => [...prevData, newCaseStudy]);
    navigate("/case-study");
  };

  const manageModalClose = () => {
    setIsOpen(false);
    setIsDeleteLoading(false);
    setDeleteId(null);
  };

  const manageConfirmDelete = () => {
    setIsDeleteLoading(true);
    deleteCaseStudy(deleteId)
      .then((res) => {
        if (res) {
          setIsOpen(false);
          fetchAllCaseStudies();
          manageModalClose();
          toast.success("Case study deleted successfully");
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        manageModalClose();
      });
  };

  return (
    <Sidebar>
      {location.pathname === "/case-study" ? (
        <Box>
          <Card sx={{ p: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Case Study</Typography>
              <Button
                onClick={() => navigate("/case-study/create")}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Case Study
              </Button>
            </Stack>
          </Card>
          {isLoading ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              mt={4}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {data?.length === 0 ? (
                <Typography variant="h4" mt={4} textAlign="center">
                  No Data Found
                </Typography>
              ) : (
                <CaseStudyList data={data} onDelete={onDelete} />
              )}
            </>
          )}
        </Box>
      ) : (
        <Box>
          <Outlet context={{ handleAddCaseStudy }} />
        </Box>
      )}
      {isOpen && (
        <WarningModal
          name={"Case Study"}
          open={isOpen}
          handleClose={manageModalClose}
          handleConfirmDelete={manageConfirmDelete}
          isDeleteLoading={isDeleteLoading}
        />
      )}
    </Sidebar>
  );
};

export default withAuth(CaseStudy);
