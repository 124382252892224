import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const Quote = ({ content }) => {
  const theme = useTheme();
  const { text, caption } = content.data;

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        borderLeft: `5px solid ${theme.palette.grey[400]}`,
        margin: `${theme.spacing(2)} 0`,
        fontStyle: "italic",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.9rem",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "1rem",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "1.2rem",
        },
      }}
    >
      <Typography variant="body1">"{text}"</Typography>
      {caption && (
        <Typography variant="caption" align="right">
          — {caption}
        </Typography>
      )}
    </Box>
  );
};

export default Quote;
