import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { deleteCategory, getAllCategory } from "../../../api";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningModal from "../../../component/WarningModal";

const CategoryList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    fetchAllCategory();
  }, []);

  const fetchAllCategory = () => {
    setLoading(true);
    getAllCategory()
      .then((res) => {
        if (res?.data.success) {
          setData(res?.data?.data);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmDelete = (id) => {
    setIsDeleteLoading(true);
    deleteCategory(id)
      .then((res) => {
        if (res) {
          fetchAllCategory();
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        closeDeleteDialog();
      });
  };

  const openDeleteDialog = (id) => {
    setDeleteId(id);
    setIsOpenDeleteModal(true);
  };

  const closeDeleteDialog = () => {
    setDeleteId(null);
    setIsDeleteLoading(false);
    setIsOpenDeleteModal(false);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: isSmallScreen ? 4 : 6,
      sortable: true,
      renderCell: (params) => (
        <Typography p={0}>
          {params.row.title
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Typography>
      ),
    },
    {
      field: "_id",
      headerName: "Action",
      flex: isSmallScreen ? 2 : 1,
      renderCell: (params) => (
        <Stack direction={"row"}>
          <IconButton
            onClick={() =>
              navigate(`/category/manage-category/${params.row._id}`)
            }
          >
            <ModeEditIcon />
          </IconButton>
          <IconButton onClick={() => openDeleteDialog(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box>
      <Paper>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={200}
          >
            <CircularProgress />
          </Box>
        ) : data.length > 0 ? (
          <DataGrid
            rows={data.map((ele) => ({
              ...ele,
              id: ele?._id,
            }))}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            disableColumnMenu={false}
          />
        ) : (
          <Typography p={4} variant="h4" textAlign="center">
            No Data found
          </Typography>
        )}
        {isOpenDeleteModal && (
          <WarningModal
            name={"Category"}
            handleClose={closeDeleteDialog}
            isDeleteLoading={isDeleteLoading}
            open={isOpenDeleteModal}
            handleConfirmDelete={() => confirmDelete(deleteId)}
          />
        )}
      </Paper>
    </Box>
  );
};

export default CategoryList;
