import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const NotFound = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <Box
      height={"100vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <Typography fontSize={140} fontWeight={"bold"}>
        404
      </Typography>
      <Typography mt={10} fontSize={34}>
        Page not found
      </Typography>
      <Box mt={4}>
        <Link
          style={{ fontSize: "24px" }}
          to={isAuthenticated ? "/blogs" : "/login"}
        >
          Go to Home{" "}
        </Link>
      </Box>
    </Box>
  );
};

export default NotFound;
