import React from "react";
import Sidebar from "../../component/Sidebar";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CategoryList from "./List";
import withAuth from "../../hooks/withauth";

const Category = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Sidebar>
      {location.pathname === "/category" ? (
        <Box>
          <Card sx={{ p: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Category</Typography>
              <Button
                onClick={() => navigate("/category/manage-category")}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Category
              </Button>
            </Stack>
          </Card>
          <Box mt={3}>
            <CategoryList />
          </Box>
        </Box>
      ) : (
        <Outlet />
      )}
    </Sidebar>
  );
};

export default withAuth(Category);
