import React from "react";
import Header from "./Header";
import Paragraph from "./Paragraph";
import Image from "./Image";
import List from "./List";
import Code from "./Code";
import Checklist from "./Checklist";
import Quote from "./Quote";

const Preview = ({ blocks }) => {
  return (
    <div>
      {blocks?.blocks.map((ele,index) => {
        switch (ele.type) {
          case "header":
            return <Header content={ele} />;

          case "paragraph":
            return <Paragraph content={ele} />;

          case "image":
            return <Image content={ele} />;

          case "list":
            return <List content={ele} />;

          case "code":
            return <Code content={ele} />;

          case "checklist":
            return <Checklist content={ele} />;

          case "quote":
            return <Quote content={ele} />;

          default:
            break;
        }
      })}
    </div>
  );
};

export default Preview;
