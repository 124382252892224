import React, { useEffect, useState } from "react";
import { Box, Card, Stack, TextField, Typography } from "@mui/material";
import LoadingButton from "../../../component/LoadingButton";
import {
  createCategory,
  getSingleCategory,
  updateCategory,
} from "../../../api";
import { useNavigate, useParams } from "react-router-dom";

const ManageCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      fetchSingleCategory();
    }
  }, [id]);

  const fetchSingleCategory = () => {
    getSingleCategory(id)
      .then((res) => {
        setCategory(res?.data?.data?.title);
      })
      .catch((e) => console.log(e));
  };

  const onClick = () => {
    setIsLoading(true);
    let obj = {
      title: category,
    };

    if (id) {
      updateCategory(id, obj)
        .then((res) => {
          if (res) {
            navigate("/category");
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    } else {
      createCategory(obj)
        .then((res) => {
          if (res) {
            navigate("/category");
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleBack = () => {
    navigate("/category");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <Box>
      <Card sx={{ p: 2 }}>
        <Typography variant="h5">{id ? "Edit" : "Create"} Category</Typography>
      </Card>
      <Card sx={{ p: 2, mt: 5 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <TextField
              onChange={(e) => setCategory(e.target.value)}
              value={category}
              inputProps={{ maxLength: 20 }}
              placeholder="Enter Category"
              sx={{ width: "50%" }}
            />
            <Stack direction={"row"} spacing={1}>
              <LoadingButton
                variant="outlined"
                onClick={handleBack}
                sx={{ width: 120 }}
              >
                BACK
              </LoadingButton>
              <LoadingButton
                onClick={onClick}
                isLoading={isLoading}
                variant="contained"
                sx={{ width: 120 }}
              >
                {id ? "Update" : "Submit"}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Card>
    </Box>
  );
};

export default ManageCategory;
