import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Typography } from "@mui/material";
import LoadingButton from "../../../component/LoadingButton";

const CategoryModal = ({
  isLoading = false,
  onClose,
  open,
  options,
  onChange,
  value,
  onSubmit,
}) => {
  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>Add Category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {options.length > 0 ? (
            <Autocomplete
              sx={{ my: 2 }}
              options={options}
              value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField {...params} label="Select Category" />
              )}
            />
          ) : (
            <Typography fontSize={18} fontWeight={"700"}>
              No Category found. Please add category
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          isLoading={isLoading}
          onClick={onSubmit}
          disabled={options?.length <= 0}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryModal;
