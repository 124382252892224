import React from "react";

const Code = ({ content }) => {
  const cleanedCode = content.data.code.trim();

  const codeStyle = {
    backgroundColor: "#f5f5f5",
    padding: "10px",
    borderRadius: "3px",
    fontFamily: "monospace",
    whiteSpace: "pre-wrap",
    overflowY: "auto",
    maxHeight: "300px",
    marginBlock: "30px",
  };

  return (
    <div>
      <pre style={codeStyle}>
        <code>{cleanedCode}</code>
      </pre>
    </div>
  );
};

export default Code;
