import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { deleteClientReview, getAllClientReview } from "../../../api";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const ReviewList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllClientReview();
  }, []);

  const fetchAllClientReview = () => {
    setLoading(true);
    getAllClientReview()
      .then((res) => {
        if (res?.data) {
          setLoading(false);
          setData(res?.data?.data);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const onDelete = (id) => {
    deleteClientReview(id)
      .then((res) => {
        if (res) {
          fetchAllClientReview();
        }
      })
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      field: "clientLogo",
      headerName: "Client Logo",
      flex: 6,
      sortable: false,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Client Logo"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      ),
    },
    {
      field: "designation",
      headerName: "Designation",
      flex: 6,
      sortable: true,
    },
    { field: "review", headerName: "Review", flex: 6, sortable: true },
    {
      field: "_id",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"}>
          <IconButton
            onClick={() =>
              navigate(`/client-review/manage-review/${params.row._id}`)
            }
          >
            <ModeEditIcon />
          </IconButton>
          <IconButton onClick={() => onDelete(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box>
      <Paper>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={200}
          >
            <CircularProgress />
          </Box>
        ) : data.length > 0 ? (
          <DataGrid
            rows={data.map((ele) => ({
              ...ele,
              id: ele?._id,
            }))}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            disableColumnMenu={false}
          />
        ) : (
          <Typography p={4} variant="h4" textAlign="center">
            No Data found
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default ReviewList;
