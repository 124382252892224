import React, { useEffect, useState } from "react";
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import LoadingButton from "../../../component/LoadingButton";
import {
  addClientReview,
  getSingleClientReview,
  updateClientReview,
} from "../../../api";
import { useNavigate, useParams } from "react-router-dom";

const ManageClientReview = () => {
  const [reviewInput, setReviewInput] = useState({
    logo: null,
    logoURL: "",
    description: "",
    name: "",
    designation: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchSingleReview();
    }
  }, [id]);

  const fetchSingleReview = () => {
    getSingleClientReview(id)
      .then((res) => {
        if (res?.data) {
          console.log(res?.data?.data);
          setReviewInput({
            description: res?.data?.data.review,
            name: res?.data?.data.clientName,
            logo: null,
            logoURL: res?.data?.data.clientLogo,
            designation: res?.data?.data.designation,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const handleLogoChange = (e) => {
    setReviewInput({
      ...reviewInput,
      logo: e.target.files[0],
      logoURL: URL.createObjectURL(e.target.files[0]),
    });
  };

  const manageReview = () => {
    setIsLoading(true);
    const formData = new FormData();
    if (reviewInput.logo) {
      formData.append("clientLogo", reviewInput.logo);
    }
    formData.append("clientName", reviewInput.name || "");
    formData.append("review", reviewInput.description);
    formData.append("designation", reviewInput.designation);

    if (id) {
      updateClientReview(id, formData)
        .then((res) => {
          if (res?.data) {
            setIsLoading(false);
            navigate("/client-review");
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    } else {
      addClientReview(formData)
        .then((res) => {
          if (res?.data) {
            setIsLoading(false);
            navigate("/client-review");
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    }
  };

  return (
    <Box>
      <Card sx={{ p: 2 }}>
        <Typography variant="h5">{id ? "Edit" : "Add"} Review</Typography>
      </Card>
      <Card sx={{ p: 2, mt: 5 }}>
        <Stack spacing={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={(reviewInput.logo || reviewInput.logoURL) && 4}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleLogoChange}
              style={{ display: "none" }}
              id="logo-input"
            />
            <label htmlFor="logo-input">
              <Button variant="contained" component="span">
                Upload Brand Logo
              </Button>
            </label>
            {(reviewInput.logo || reviewInput.logoURL) && (
              <Box>
                <img
                  src={
                    reviewInput.logo
                      ? URL.createObjectURL(reviewInput.logo)
                      : reviewInput.logoURL
                  }
                  alt="Client Logo"
                  height={"auto"}
                  width={100}
                />
              </Box>
            )}
          </Stack>
          <TextField
            onChange={(e) =>
              setReviewInput({ ...reviewInput, name: e.target.value })
            }
            fullWidth
            value={reviewInput.name}
            placeholder="Enter Name"
          />
          <TextField
            onChange={(e) =>
              setReviewInput({ ...reviewInput, designation: e.target.value })
            }
            fullWidth
            value={reviewInput.designation}
            placeholder="Enter Designation"
          />
          <TextField
            onChange={(e) =>
              setReviewInput({ ...reviewInput, description: e.target.value })
            }
            value={reviewInput.description}
            placeholder="Enter Description"
            rows={4}
            multiline
          />
          <Stack direction={"row"} spacing={4}>
            <LoadingButton
              onClick={manageReview}
              variant="contained"
              sx={{ width: 120 }}
              isLoading={isLoading}
            >
              Submit
            </LoadingButton>
            <Button
              onClick={() => navigate("/client-review")}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default ManageClientReview;
