/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Card,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Stack,
  IconButton,
  Paper,
  Autocomplete,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createCaseStudy,
  getSingleCaseStudy,
  updateCaseStudy,
} from "../../../api";
import LoadingButton from "../../../component/LoadingButton";
import { toast } from "react-toastify";

const categoryData = [
  {
    label: "ADVERTISEMENT",
    value: "ADVERTISEMENT",
  },
  {
    label: "BUSINESS",
    value: "BUSINESS",
  },
  {
    label: "E-COMMERCE",
    value: "E-COMMERCE",
  },
  {
    label: "EDUCATION",
    value: "EDUCATION",
  },
  {
    label: "FITNESS",
    value: "FITNESS",
  },
  {
    label: "FOOD & TRAVEL",
    value: "FOOD & TRAVEL",
  },
  {
    label: "GAMING",
    value: "GAMING",
  },
  {
    label: "HEALTHCARE",
    value: "HEALTHCARE",
  },
  {
    label: "ENTERTAINMENT",
    value: "ENTERTAINMENT",
  },
  {
    label: "HOSPITALITY",
    value: "HOSPITALITY",
  },
];
const techStackData = [
  { label: 'JAVASCRIPT', value: 'JAVASCRIPT' },
  { label: 'JAVA', value: 'JAVA' },
  { label: 'PYTHON', value: 'PYTHON' },
  { label: 'REACT.JS', value: 'REACT.JS' },
  { label: 'REACT-NATIVE', value: 'REACT-NATIVE' },
  { label: 'NEXT.JS', value: 'NEXT.JS' },
  { label: 'ANGULAR.JS', value: 'ANGULAR.JS' },
  { label: 'NODE.JS', value: 'NODE.JS' },
  { label: 'AWS', value: 'AWS' },
  { label: 'MongoDB', value: 'MongoDB' },
  { label: 'GraphQL', value: 'GraphQL' },
]

const AddCaseStudy = () => {
  const [caseStudies, setCaseStudies] = useState([
    {
      title: "",
      platform: "",
      industry: "",
      descriptions: [""],
      image: null,
      imageURL: "",
      name: "introduction",
    },
    { descriptions: [''], image: null, imageURL: '', name: 'Backstory' },
    { descriptions: [''], image: null, imageURL: '', name: 'Challenge' },
    { descriptions: [''], image: null, imageURL: '', name: 'Features' },
    { descriptions: [''], image: null, imageURL: '', name: 'Result' },
  ])
  const [techStack, setTechStack] = useState([])
  const [category, setCategory] = useState(null)
  const [caseStudyName, setCaseStudyName] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      fetchSingleCaseStudy();
    } else {
      setCaseStudies([
        {
          title: "",
          platform: "",
          industry: "",
          descriptions: [""],
          image: null,
          imageURL: "",
          name: "introduction",
        },
        { descriptions: [""], image: null, imageURL: "", name: "Backstory" },
        { descriptions: [""], image: null, imageURL: "", name: "Challenge" },
        { descriptions: [""], image: null, imageURL: "", name: "Features" },
        { descriptions: [""], image: null, imageURL: "", name: "Result" },
      ]);
      setIsFetching(false);
    }
  }, [id]);

  const fetchSingleCaseStudy = () => {
    getSingleCaseStudy(id)
      .then((res) => {
        if (res) {
          const data = res?.data?.data
          setCaseStudyName(res?.data?.data?.name)

          categoryData?.forEach((ele) => {
            if (ele.value === res?.data?.data?.category) {
              setCategory({ label: ele.label, value: ele.value });
            }
          });

          let temp = [];
          techStackData?.forEach((ele) => {
            res?.data?.data?.techStack.forEach((item) => {
              if (item === ele.value) {
                temp.push({
                  label: ele.label,
                  value: ele.value,
                });
              }
            });
          });

          setTechStack(temp);

          const updatedCaseStudies = [
            {
              title: data.introduction?.title || "",
              platform: data.introduction?.platform || "",
              industry: data.introduction?.industry || "",
              descriptions: data.introduction?.descriptions || [""],
              image: null,
              imageURL: data.introduction?.image,
              name: "introduction",
            },
            {
              descriptions: data.backstory?.descriptions || [""],
              image: null,
              imageURL: data.backstory?.image,
              name: "Backstory",
            },
            {
              descriptions: data.challenge?.descriptions || [""],
              image: null,
              imageURL: data.challenge?.image,
              name: "Challenge",
            },
            {
              descriptions: data.solution?.descriptions || [""],
              image: null,
              imageURL: data.solution?.image,
              name: "Features",
            },
            {
              descriptions: data.result.descriptions || [""],
              image: null,
              imageURL: data.result?.image,
              name: "Result",
            },
          ];

          setCaseStudies(updatedCaseStudies);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleInputChange = (index, field, value, descriptionIndex = null) => {
    const updatedCaseStudies = [...caseStudies];
    if (field === "descriptions" && descriptionIndex !== null) {
      updatedCaseStudies[index].descriptions[descriptionIndex] = value;
    } else {
      updatedCaseStudies[index][field] = value;
    }
    setCaseStudies(updatedCaseStudies);
  };

  const handleAddDescription = (index) => {
    const updatedCaseStudies = [...caseStudies];
    updatedCaseStudies[index].descriptions.push("");
    setCaseStudies(updatedCaseStudies);
  };

  const handleDeleteDescription = (index, descriptionIndex) => {
    const updatedCaseStudies = [...caseStudies]
    updatedCaseStudies[index].descriptions.splice(descriptionIndex, 1)
    setCaseStudies(updatedCaseStudies)
  }
  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const updatedCaseStudies = [...caseStudies];
      updatedCaseStudies[index].image = file;
      setCaseStudies(updatedCaseStudies);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('category', category?.value)
    formData.append('name', caseStudyName)
    techStack.forEach((tech, index) => {
      formData.append(`techStack[${index}]`, tech.value);
    });

    const appendCaseStudyData = (prefix, data) => {
      formData.append(`${prefix}[title]`, data.title || "");
      formData.append(`${prefix}[platform]`, data.platform || "");
      formData.append(`${prefix}[industry]`, data.industry || "");
      data.descriptions.forEach((desc, index) => {
        formData.append(`${prefix}[descriptions][${index}]`, desc);
      });
      if (data.image instanceof File) {
        formData.append(`${prefix}[image]`, data.image);
      } else if (data.imageURL) {
        formData.append(`${prefix}[imageURL]`, data.imageURL);
      }
    }
    appendCaseStudyData('introduction', caseStudies[0])
    appendCaseStudyData('backstory', caseStudies[1])
    appendCaseStudyData('challenge', caseStudies[2])
    appendCaseStudyData('solution', caseStudies[3])
    appendCaseStudyData('result', caseStudies[4])

    if (id) {
      formData.append("id", id);
    }

    if (id) {
      updateCaseStudy(id, formData)
        .then((res) => {
          if (res) {
            if (res?.data?.success) {
              setIsLoading(false);
              navigate("/case-study");
              toast.success("Case study updated successfully");
            }
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
          setIsLoading(false);
        });
    } else {
      createCaseStudy(formData)
        .then((res) => {
          if (res?.data?.success) {
            setIsLoading(false);
            navigate("/case-study");
            toast.success("Case study created successfully");
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
          setIsLoading(false);
        });
    }
  };

  const handleCancel = () => {
    navigate("/case-study");
  };

  return (
    <Box>
      <Card sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5">Add Case Study</Typography>
      </Card>

      <Box sx={{ mb: 2 }}>
        <Paper>
          <Box sx={{ padding: 2, margin: 0 }}>
            <TextField
              label="Name"
              value={caseStudyName}
              onChange={(e) => setCaseStudyName(e.target.value)}
              fullWidth
            />
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2,
              paddingTop: 0,
              paddingLeft: 0,
              margin: 0,
            }}
          >
            <Grid item md={6}>
              <Box>
                <Autocomplete
                  size="small"
                  options={categoryData}
                  getOptionLabel={(option) => option.label}
                  value={category}
                  onChange={(_, newValue) => setCategory(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      placeholder="Choose A Category..."
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box>
                <Autocomplete
                  multiple
                  size="small"
                  options={techStackData}
                  getOptionLabel={(option) => option.label}
                  value={techStack}
                  onChange={(_, newVal) => setTechStack(newVal)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="TechStack"
                      placeholder="Choose TechStack Category..."
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontSize={22} fontWeight={"600"}>
              Introduction
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <TextField
                label="Title"
                value={caseStudies[0].title}
                onChange={(e) => handleInputChange(0, "title", e.target.value)}
                fullWidth
              />
              <TextField
                label="Platform"
                value={caseStudies[0].platform}
                onChange={(e) =>
                  handleInputChange(0, "platform", e.target.value)
                }
                fullWidth
              />
              <TextField
                label="Industry"
                value={caseStudies[0].industry}
                onChange={(e) =>
                  handleInputChange(0, "industry", e.target.value)
                }
                fullWidth
              />
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <div
                      onClick={() =>
                        document.getElementById("file-upload-0").click()
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        display: "inline-block",
                        cursor: "pointer",
                        width: "200px",
                      }}
                    >
                      <Typography>
                        {caseStudies[0].image
                          ? caseStudies[0].image.name
                          : "Upload Image"}
                      </Typography>
                    </div>
                    {(caseStudies[0].image || caseStudies[0].imageURL) && (
                      <img
                        src={
                          caseStudies[0].image
                            ? URL.createObjectURL(caseStudies[0].image)
                            : caseStudies[0].imageURL
                        }
                        alt="Uploaded"
                        width={70}
                        height={"auto"}
                      />
                    )}
                    <input
                      type="file"
                      id="file-upload-0"
                      style={{ display: "none" }}
                      onChange={(e) => handleImageUpload(0, e)}
                    />
                  </Stack>
                  <IconButton
                    color="primary"
                    onClick={() => handleAddDescription(0)}
                  >
                    <AddIcon />
                  </IconButton>
                </Stack>
              </Box>
              {caseStudies[0].descriptions.map(
                (description, descriptionIndex) => (
                  <Stack
                    key={descriptionIndex}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <TextField
                      label={`Description ${descriptionIndex + 1}`}
                      multiline
                      rows={2}
                      value={description}
                      onChange={(e) =>
                        handleInputChange(
                          0,
                          "descriptions",
                          e.target.value,
                          descriptionIndex
                        )
                      }
                      fullWidth
                    />
                    {descriptionIndex > 0 && (
                      <IconButton
                        color="error"
                        onClick={() =>
                          handleDeleteDescription(0, descriptionIndex)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                )
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
      {caseStudies.slice(1).map((study, index) => (
        <Box key={index + 1} sx={{ marginBottom: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontSize={22} fontWeight={"600"}>
                {study.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <div
                      onClick={() =>
                        document
                          .getElementById(`file-upload-${index + 1}`)
                          .click()
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        display: "inline-block",
                        cursor: "pointer",
                        width: "200px",
                      }}
                    >
                      <Typography>
                        {study.image ? study.image.name : "Upload Image"}
                      </Typography>
                    </div>
                    {(study.image || study.imageURL) && (
                      <img
                        src={
                          study?.image
                            ? URL.createObjectURL(study.image)
                            : study.imageURL
                        }
                        alt="Uploaded"
                        width={70}
                        height={"auto"}
                      />
                    )}
                    <input
                      type="file"
                      id={`file-upload-${index + 1}`}
                      style={{ display: "none" }}
                      onChange={(e) => handleImageUpload(index + 1, e)}
                    />
                  </Stack>
                  <IconButton
                    color="primary"
                    onClick={() => handleAddDescription(index + 1)}
                  >
                    <AddIcon />
                  </IconButton>
                </Stack>
                {study.descriptions.map((description, descriptionIndex) => (
                  <div
                    key={descriptionIndex}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TextField
                      label={`Description ${descriptionIndex + 1}`}
                      multiline
                      rows={2}
                      value={description}
                      onChange={(e) =>
                        handleInputChange(
                          index + 1,
                          "descriptions",
                          e.target.value,
                          descriptionIndex
                        )
                      }
                      fullWidth
                    />
                    {descriptionIndex > 0 && (
                      <IconButton
                        color="error"
                        onClick={() =>
                          handleDeleteDescription(index + 1, descriptionIndex)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
      <Card
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          mt: 2,
          p: 2,
        }}
      >
        <LoadingButton
          isLoading={isLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancel
        </Button>
      </Card>
    </Box>
  );
};

export default AddCaseStudy;
