import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSingleBlog } from "../../../api";
import Preview from "../preview";
import {
  Box,
  Container,
  Paper,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ViewBlog = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchSingleBlog();
    }
  }, [id]);

  const fetchSingleBlog = async () => {
    try {
      const res = await getSingleBlog(id);
      if (res) {
        setData(JSON.parse(res.data?.data?.content));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBack = () => {
    navigate("/blogs");
  };

  return (
    <Container
      sx={{
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      }}
    >
      {data ? (
        <Paper
          sx={{
            padding: isSmallScreen ? theme.spacing(2) : theme.spacing(4),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ maxWidth: isSmallScreen ? "100%" : "800px" }}>
            <IconButton sx={{ mb: 2 }} onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>{" "}
            <Preview blocks={data} />
          </Box>
        </Paper>
      ) : (
        <Typography variant="h6" textAlign="center">
          Loading...
        </Typography>
      )}
    </Container>
  );
};

export default ViewBlog;
