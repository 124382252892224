import React from "react";
import { Checkbox, List, ListItem, ListItemText } from "@mui/material";

const Checklist = ({ content }) => {
  const checklistItems = content.data.items ?? [];

  return (
    <List>
      {checklistItems.map((item) => (
        <ListItem key={item.id} disablePadding>
          <Checkbox checked={item.checked} disabled color="primary" />
          <ListItemText primary={item.text.replace(/&nbsp;/g, " ")} />
        </ListItem>
      ))}
    </List>
  );
};

export default Checklist;
