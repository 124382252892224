import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LazyLoadImage } from "react-lazy-load-image-component";

const getElements = (content, type) =>
  content.blocks?.filter((ele) => ele.type === type);

const stripLinks = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  div.querySelectorAll("a").forEach((a) => a.remove());
  return div.innerHTML;
};

const BlogCard = ({ content, item, onDelete, category }) => {
  const navigate = useNavigate();

  const blogTitle = React.useMemo(() => {
    const headers = getElements(content, "header");
    return headers?.[0]?.data.text || "No Title";
  }, [content]);

  const blogImage = React.useMemo(() => {
    const images = getElements(content, "image");
    return images?.[0]?.data?.file?.url || null;
  }, [content]);

  const blogDescription = React.useMemo(() => {
    const paragraphs = getElements(content, "paragraph");
    const fullDescription = paragraphs?.map((p) => p.data.text).join(" ") || "";
    const strippedDescription = stripLinks(fullDescription).trim();
    return strippedDescription || "No Description";
  }, [content]);

  const navigateToEdit = () => {
    navigate(`/blogs/edit/${item?.title}`);
  };

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {blogImage && (
        <CardMedia>
          <LazyLoadImage
            src={blogImage}
            alt="Blog image"
            style={{ width: "100%", height: 200, objectFit: "cover" }}
          />
        </CardMedia>
      )}

      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          variant="h5"
          mt={1}
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitAlignContent: "space-between",
            WebkitLineClamp: 2,
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => navigate(`/blogs/view/${item?.title}`)}
        >
          <div dangerouslySetInnerHTML={{ __html: blogTitle }} />
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            mt: 2,
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: blogDescription }} />
        </Typography>

        <Typography mt={1}>
          Category:{" "}
          <span>
            {category
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </span>
        </Typography>
      </CardContent>

      <Box sx={{ mt: "auto", p: 2 }}>
        <Stack
          direction="row"
          display={"flex"}
          justifyContent={"flex-end"}
          spacing={1}
        >
          <IconButton color="primary" onClick={navigateToEdit}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" onClick={() => onDelete(item.title)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Box>
    </Card>
  );
};

export default BlogCard;
