import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllCategory, getSingleBlog, updateBlog } from "../../../api";
import { Box, Container, IconButton, Paper, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import LoadingButton from "../../../component/LoadingButton";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import InlineCode from "@editorjs/inline-code";
import Marker from "@editorjs/marker";
import Delimiter from "@editorjs/delimiter";
import ImageTool from "@editorjs/image";
import Embed from "@editorjs/embed";
import Checklist from "@editorjs/checklist";
import CodeTool from "@editorjs/code";
import { toast } from "react-toastify";
import CategoryModal from "../CatergoryModal";

const EditBlog = () => {
  const [editorblock, setEditorBlock] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editData, setEditData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (id && categoryData.length > 0) {
      fetchSingleBlog();
    }
  }, [id, categoryData]);

  useEffect(() => {
    fetchAllCategory();
  }, []);

  const fetchAllCategory = () => {
    getAllCategory()
      .then((res) => {
        if (res) {
          let temp = res.data.data.map((ele) => ({
            label: ele.title
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            value: ele._id,
          }));
          setCategoryData(temp);
        }
      })
      .catch((e) => console.log(e));
  };
  const fetchSingleBlog = () => {
    getSingleBlog(id)
      .then((res) => {
        if (res) {
          const categoryTitle = res?.data?.data?.category
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          let temp = categoryData.find((ele) => ele.label === categoryTitle);
          setSelectedCategory(temp);
          setEditData(JSON.parse(res.data.data.content));
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (editData) {
      new EditorJS({
        holder: "editBlog",
        placeholder: "Start writing your blog...",
        autofocus: false,
        data: editData,
        tools: {
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          checklist: Checklist,
          list: List,
          header: Header,
          delimiter: Delimiter,
          inlineCode: InlineCode,
          marker: Marker,
          quote: Quote,
          embed: Embed,
          code: CodeTool,
          image: {
            class: ImageTool,
            config: {
              uploader: {
                async uploadByFile(file) {
                  const formData = new FormData()
                  formData.append('image', file)

                  try {
                    const response = await axios.post(
                      `https://api.technithunder.com/api/imageUpload`,
                      formData,
                      {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        withCredentials: false,
                      },
                    )

                    if (
                      response?.data?.success === 1 &&
                      response.data.file?.url
                    ) {
                      return {
                        success: 1,
                        file: {
                          url: response.data.file.url,
                        },
                      }
                    } else {
                      console.error(
                        'Invalid response structure:',
                        response.data,
                      )
                      return {
                        success: 0,
                        message: 'Invalid response structure',
                      }
                    }
                  } catch (error) {
                    console.error('Image upload error:', error)
                    return {
                      success: 0,
                      message: error.message || 'Unknown error occurred',
                    }
                  }
                },
              },
            },
          },
        },
        async onChange(api, event) {
          const savedData = await api.saver.save();
          setEditorBlock(savedData);
        },
      });
    }
  }, [editData]);

  const checkForHeaderAndImage = (data) => {
    if (!data) return false;

    const blocks = data.blocks || [];
    const hasHeader = blocks.some((block) => block?.type === "header");
    const hasImage = blocks.some((block) => block?.type === "image");

    return hasHeader && hasImage;
  };

  const onPublish = () => {
    if (!checkForHeaderAndImage(editorblock)) {
      alert("Please add a heading and an image before publishing.");
      return;
    }
    setIsOpen(true);
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const onSubmit = () => {
    setIsLoading(true);
    let obj = {
      title: id,
      content: JSON.stringify(editorblock),
      category: selectedCategory?.label,
    };

    updateBlog(id, obj)
      .then((res) => {
        if (res) {
          setIsLoading(false);
          navigate("/blogs");
          toast.success("Blog updated successfully");
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <Paper sx={{ py: 2 }}>
      <Container>
        <Stack justifyContent="space-between" direction="row">
          <IconButton onClick={() => navigate("/blogs")}>
            <ArrowBackIcon />
          </IconButton>
          <LoadingButton
            onClick={onPublish}
            variant="contained"
            disabled={!editorblock}
          >
            Update
          </LoadingButton>
        </Stack>
        <Box sx={{ mt: 2 }}>
          <div id={"editBlog"} />
        </Box>
      </Container>
      <CategoryModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        options={categoryData}
        onChange={handleCategoryChange}
        value={selectedCategory}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Paper>
  );
};

export default EditBlog;
