import React, { useState, useEffect } from "react";
import { Dialog, Box, Typography } from "@mui/material";

const Image = ({ content }) => {
  const { data } = content;
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;

      if (scrollPosition > threshold && isOpen) {
        closeDialog();
      }
    };

    document.addEventListener("scroll", handleScroll, true);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  const imageStyle = {
    height: "auto",
    width: "100%",
    margin: "0 auto",
    cursor: "pointer",
    transition: "transform 0.3s ease",
  };

  const caption = data?.caption || "Image caption";

  return (
    <div style={{ margin: "35px 0 20px 0", textAlign: "center" }}>
      {data?.file?.url ? (
        <>
          <img
            src={data?.file?.url}
            alt="blog-image"
            style={imageStyle}
            onClick={openDialog}
          />
          <Typography variant="body1">{caption}</Typography>{" "}
          <Dialog
            open={isOpen}
            onClose={closeDialog}
            style={{ transition: "opacity 0.3s" }}
          >
            <Box
              style={{
                width: "80%",
                margin: "0 auto",
                transform: isOpen ? "scale(1)" : "scale(0.8)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <img
                src={data?.file?.url}
                alt="dialog-image"
                style={{
                  height: "auto",
                  width: "100%",
                }}
              />
              <Typography
                variant="caption"
                component="p"
                style={{ textAlign: "center" }}
              >
                {caption}
              </Typography>{" "}
            </Box>
          </Dialog>
        </>
      ) : (
        <p>No image available</p>
      )}
    </div>
  );
};

export default Image;
